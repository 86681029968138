import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { darkTheme } from '../AppGlobals';
import { LoggedUserInfoForMenu } from './LoggedUserInfoForMenu';
import { Nav, INavLink } from '@fluentui/react/lib/Nav';
import { IEntity } from '../types';
import { Icon } from '@fluentui/react';

interface IZedXMainMenu {
    onLinkClicked?: () => void;
    onLogout?: () => void;
    authType: string;
    menuData?: any[];
    lastFiveApps: IEntity[];
}

export const ZedXMainMenu = ({ onLogout, authType, onLinkClicked, menuData, lastFiveApps }: IZedXMainMenu): React.ReactElement => {

    const { pathname } = useLocation();
    const history = useHistory();
    const [navs, setNavs] = useState([]);

    // - Corporate Workflows
    // - Project Portfolios
    // - Risk Registers
    // - GIAA Actions
    // - NAO/PAC Tracker
    // - Contingent Labour
    // - Governance Statements
    // - Controls and Assurance
    // - Management Actions
    // - Agile Sprints
    // - Knowledge Hub
    // - Group Notifications

    useEffect(() => {

        const routerNav = (path: string): void => {
            history.push(path);
            if (onLinkClicked) onLinkClicked();
        };

        const NamePathToNavLink = ({ name, path }): INavLink => {
            return { key: path, name: name, url: `#${path}`, onClick: () => routerNav(path) };
        };

        const links: INavLink[] = [
            { name: 'Home', path: '/' },
        ].map(NamePathToNavLink);

        const allAppsLinks = [];
        const recentAppsLinks = [];

        const menuDataMap = new Map(menuData.map(item => [item.ID, item]));

        lastFiveApps.forEach((value) => {
            const menuItem = menuDataMap.get(value.ID);
            if (menuItem) {
                recentAppsLinks.push({
                    name: menuItem.MenuText,
                    key: `m_recent_${value.ID}`,
                    path: `${menuItem.AppLink}/welcome`,
                });
            }
        });

        menuData.forEach(m => {
            allAppsLinks.push({
                name: m.MenuText,
                key: `m_all_${m.ID}`,
                path: `${m.AppLink}/welcome`,
            });
        });

        links.push({
            key: 'recent_apps',
            name: 'Recent Apps',
            url: null,
            isExpanded: true,
            links: recentAppsLinks.map(NamePathToNavLink)
        });

        links.push({
            key: 'all_apps',
            name: 'All Apps',
            url: null,
            isExpanded: false,
            links: allAppsLinks.map(NamePathToNavLink)
        });

        links.push(NamePathToNavLink({ name: 'Account Admin', path: '/admin/welcome' }));

        if (authType == 'CustomJwt') {
            links.push(NamePathToNavLink({ name: 'Change Password', path: '/account/change-password' }));
        }
        links.push(
            {
                name: 'Logout',
                class: 'logout-link',
                key: 'logout',
                url: '',
                onClick: () => {
                    onLogout();
                }
            },
        );

        setNavs(links);

    }, [history, lastFiveApps, onLinkClicked]);

    const selectedKey: string = pathname === "/" ? "home" : pathname;
    return (
        <ThemeProvider theme={darkTheme}>
            <LoggedUserInfoForMenu />
            <Nav
                groups={[{ links: navs }]}
                selectedKey={selectedKey}
                onRenderLink={(lnk) => _onRenderNavLink(lnk, 0, true)}
            />
        </ThemeProvider>
    );
};


export function _onRenderNavLink(link: INavLink, totalCount?: number, mainMenu?: boolean) {
    // Common styles
    const commonStyle = { paddingLeft: '10px' };
    const parentChildStyle = { marginLeft: '5px' };
    const iconStyle = { root: { fontSize: '13px', /*color: '#99D9EA',*/ marginRight: '5px' } };
    const badgeStyle = {
        fontSize: '12px',
        backgroundColor: 'green',
        color: 'white',
        padding: '2px',
        paddingLeft: '4px',
        paddingRight: '22px',
        borderRadius: '4px'
    };

    // Common icon or image render function
    const renderIconOrImage = (iconName?: string) => (
        iconName ? <Icon iconName={iconName} styles={iconStyle} /> : <img width='8' height='8' src='https://placehold.co/10x10/99D9EA/99D9EA' />
    );

    if (mainMenu === true) {
        switch (link.key) {
            case '/':
                return (
                    <span>
                        {renderIconOrImage("Home")}
                        <span style={commonStyle}>{link.name}</span>
                    </span>
                );

            case 'recent_apps':
                return (
                    <span style={parentChildStyle}>
                        {renderIconOrImage("Recent")}
                        <span style={commonStyle}>{link.name}</span>
                    </span>
                );
            case 'all_apps':
                return (
                    <span style={parentChildStyle}>
                        {renderIconOrImage("AllApps")}
                        <span style={commonStyle}>{link.name}</span>
                    </span>
                );
            case '/admin/welcome':
                return (
                    <span>
                        {renderIconOrImage("Admin")}
                        <span style={commonStyle}>{link.name}</span>
                    </span>
                );

            case '/account/change-password':
                return (
                    <span>
                        {renderIconOrImage("PasswordField")}
                        <span style={commonStyle}>{link.name}</span>
                    </span>
                );

            case 'logout':
                return (
                    <span>
                        {renderIconOrImage("SignOut")}
                        <span style={commonStyle}>{link.name}</span>
                    </span>
                );


            default:
                if (link.key.endsWith('/welcome')) {
                    return (
                        <span style={parentChildStyle}>
                            {renderIconOrImage("AppIconDefault")}
                            <span style={commonStyle}>{link.name}</span>
                        </span>
                    );
                }

                return <span>{link.name}</span>;
        }

    }

    // Render based on link key
    switch (link.key) {
        case '/performance-reporting/portfolios':
            return (
                <span>
                    {renderIconOrImage()}
                    <span style={{ ...commonStyle, marginRight: '25px' }}>{link.name}</span>
                    <span style={badgeStyle}>{totalCount}</span>
                </span>
            );

        case '/performance-reporting/programmes':
        case '/performance-reporting/projects':
            return (
                <span>
                    {renderIconOrImage()}
                    <span style={commonStyle}>{link.name}</span>
                </span>
            );

        case '/':
            return (
                <span>
                    {renderIconOrImage("Home")}
                    <span style={commonStyle}>{link.name}</span>
                </span>
            );

        case '/giaa-actions/welcome':
        case '/nao-tracker/welcome':
        case '/contingent-labour-processes/welcome':
        case '/management-actions/welcome':
        case '/corporate-workflows/welcome':
        case '/agile-sprints/welcome':
        case '/agile-sprints/welcome':
        case '/controls-assurance/welcome':
        case '/governance/welcome':
        case '/group-notifications/welcome':
        case '/knowledge-hub/welcome':
        case '/risk-reporting/welcome':
        case '/performance-reporting/welcome':
            return (
                <span>
                    {renderIconOrImage("WavingHand")}
                    <span style={commonStyle}>{link.name}</span>
                </span>
            );

        case '/giaa-actions/updates':
        case '/nao-tracker/updates':
        case '/management-actions/updates':
        case '/contingent-labour-processes/manage-cases':
        case '/corporate-workflows/workflows':
            return (
                <span>
                    {renderIconOrImage("ReportLibrary")}
                    <span style={{ ...commonStyle, marginRight: '25px' }}>{link.name}</span>
                    <span style={badgeStyle}>{totalCount}</span>
                </span>
            );

        case '/controls-assurance/updates':
        case '/governance/updates':
        case '/risk-reporting/risks':
            return (
                <span>
                    {renderIconOrImage("SpeedHigh")}
                    <span style={{ ...commonStyle }}>{link.name}</span>
                </span>
            );

        case '/risk-reporting/risk-mitigating-actions':
            return (
                <span>
                    {renderIconOrImage("Umbrella")}
                    <span style={{ ...commonStyle }}>{link.name}</span>
                </span>
            );

        case 'Management':
            return (
                <span>
                    {renderIconOrImage("ProjectCollection")}
                    <span style={{ ...commonStyle }}>{link.name}</span>
                </span>
            );

        case '/giaa-actions/outputs':
        case '/nao-tracker/outputs':
        case '/contingent-labour-processes/outputs':
        case '/governance/outputs':
            return (
                <span>
                    {renderIconOrImage("Send")}
                    <span style={commonStyle}>{link.name}</span>
                </span>
            );

        case '/giaa-actions/properties':
        case '/nao-tracker/properties':
        case '/contingent-labour-processes/properties':
        case '/management-actions/properties':
        case '/controls-assurance/properties':
        case '/governance/properties':
            return (
                <span>
                    {renderIconOrImage("Settings")}
                    <span style={commonStyle}>{link.name}</span>
                </span>
            );

        default:
            return <span>{link.name}</span>;
    }
}
